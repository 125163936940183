exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-an-overview-js": () => import("./../../../src/pages/about/an-overview.js" /* webpackChunkName: "component---src-pages-about-an-overview-js" */),
  "component---src-pages-about-leadership-index-js": () => import("./../../../src/pages/about/leadership/index.js" /* webpackChunkName: "component---src-pages-about-leadership-index-js" */),
  "component---src-pages-about-leadership-strapi-leadership-list-slug-js": () => import("./../../../src/pages/about/leadership/{strapiLeadershipList.slug}.js" /* webpackChunkName: "component---src-pages-about-leadership-strapi-leadership-list-slug-js" */),
  "component---src-pages-blogs-and-vlogs-index-js": () => import("./../../../src/pages/blogs-and-vlogs/index.js" /* webpackChunkName: "component---src-pages-blogs-and-vlogs-index-js" */),
  "component---src-pages-blogs-and-vlogs-strapi-blog-and-vlog-slug-js": () => import("./../../../src/pages/blogs-and-vlogs/{strapiBlogAndVlog.slug}.js" /* webpackChunkName: "component---src-pages-blogs-and-vlogs-strapi-blog-and-vlog-slug-js" */),
  "component---src-pages-book-an-appointment-cash-patient-js": () => import("./../../../src/pages/book-an-appointment/cash-patient.js" /* webpackChunkName: "component---src-pages-book-an-appointment-cash-patient-js" */),
  "component---src-pages-book-an-appointment-index-js": () => import("./../../../src/pages/book-an-appointment/index.js" /* webpackChunkName: "component---src-pages-book-an-appointment-index-js" */),
  "component---src-pages-book-an-appointment-insurance-patient-js": () => import("./../../../src/pages/book-an-appointment/insurance-patient.js" /* webpackChunkName: "component---src-pages-book-an-appointment-insurance-patient-js" */),
  "component---src-pages-centers-of-excellence-index-js": () => import("./../../../src/pages/centers-of-excellence/index.js" /* webpackChunkName: "component---src-pages-centers-of-excellence-index-js" */),
  "component---src-pages-centers-of-excellence-strapi-centers-of-excellence-slug-js": () => import("./../../../src/pages/centers-of-excellence/{strapiCentersOfExcellence.slug}.js" /* webpackChunkName: "component---src-pages-centers-of-excellence-strapi-centers-of-excellence-slug-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-doctors-index-js": () => import("./../../../src/pages/doctors/index.js" /* webpackChunkName: "component---src-pages-doctors-index-js" */),
  "component---src-pages-doctors-strapi-doctor-slug-js": () => import("./../../../src/pages/doctors/{strapiDoctor.slug}.js" /* webpackChunkName: "component---src-pages-doctors-strapi-doctor-slug-js" */),
  "component---src-pages-feedback-index-js": () => import("./../../../src/pages/feedback/index.js" /* webpackChunkName: "component---src-pages-feedback-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-and-events-index-js": () => import("./../../../src/pages/news-and-events/index.js" /* webpackChunkName: "component---src-pages-news-and-events-index-js" */),
  "component---src-pages-news-and-events-strapi-news-and-event-slug-js": () => import("./../../../src/pages/news-and-events/{strapiNewsAndEvent.slug}.js" /* webpackChunkName: "component---src-pages-news-and-events-strapi-news-and-event-slug-js" */),
  "component---src-pages-patient-insurance-index-js": () => import("./../../../src/pages/patient-insurance/index.js" /* webpackChunkName: "component---src-pages-patient-insurance-index-js" */),
  "component---src-pages-request-appointment-index-js": () => import("./../../../src/pages/request-appointment/index.js" /* webpackChunkName: "component---src-pages-request-appointment-index-js" */),
  "component---src-pages-second-opinion-js": () => import("./../../../src/pages/second-opinion.js" /* webpackChunkName: "component---src-pages-second-opinion-js" */),
  "component---src-pages-specialties-index-js": () => import("./../../../src/pages/specialties/index.js" /* webpackChunkName: "component---src-pages-specialties-index-js" */),
  "component---src-pages-specialties-strapi-speciality-slug-js": () => import("./../../../src/pages/specialties/{strapiSpeciality.slug}.js" /* webpackChunkName: "component---src-pages-specialties-strapi-speciality-slug-js" */),
  "component---src-pages-technologies-index-js": () => import("./../../../src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-technologies-strapi-technology-slug-js": () => import("./../../../src/pages/technologies/{strapiTechnology.slug}.js" /* webpackChunkName: "component---src-pages-technologies-strapi-technology-slug-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */),
  "component---src-pages-testimonials-strapi-testimonial-slug-js": () => import("./../../../src/pages/testimonials/{strapiTestimonial.slug}.js" /* webpackChunkName: "component---src-pages-testimonials-strapi-testimonial-slug-js" */),
  "component---src-pages-top-procedures-index-js": () => import("./../../../src/pages/top-procedures/index.js" /* webpackChunkName: "component---src-pages-top-procedures-index-js" */),
  "component---src-pages-top-procedures-strapi-top-procedur-slug-js": () => import("./../../../src/pages/top-procedures/{strapiTopProcedur.slug}.js" /* webpackChunkName: "component---src-pages-top-procedures-strapi-top-procedur-slug-js" */)
}

